import { useClasses } from 'hooks';
import { Grid } from '@mui/material';

import { useMarketContext } from 'context/BudMarketContext';
import { formatNumber } from 'utils/utils';
import MarketToolbar from '../Toolbar';
import { styles } from './styles';
import MyNFTs from '../MyNFTs';

const TokenProfile = () => {
  const { tokenBalance } = useMarketContext();
  const { container, overViewItem } = useClasses(styles);

  return (
    <div className={container}>
      <MyNFTs/>
      <MarketToolbar />
    </div>
  );
};

export default TokenProfile;
