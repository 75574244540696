import axios from 'axios';

const webhooks_url =
  'https://discord.com/api/webhooks/1092392877243510844/jAMUDoKLQRFn07HDxmqTWwhCScdpqTDIZTx-lPw1OVHZePpDQ4tv8SMUMr70ibwa8ZAs';

export const sendDiscordMessage = async (message: string) => {
  const payload = { content: message };
  return axios
    .post(webhooks_url, payload)
    .then(() => true)
    .catch((e) => {
      console.error(e);
      return false;
    });
};

export const sendTokenStakedMessage = async (tokenId: string, address: string, transactionId: string) => {
  const message = [
    `BUDDIE #${tokenId} has just been staked by wallet address ${address}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
    `Token: https://moonsama.mypinata.cloud/ipfs/QmR3kpCusAKYjSYz3Dzh8bHfAaaCNE7N1sBdbvP8opGXKd/${tokenId}.png`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendTokenUnStakedMessage = async (tokenId: string, address: string, transactionId: string) => {
  const message = [
    `BUDDIE #${tokenId} has just been unstaked by wallet address ${address}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
    `Token: https://moonsama.mypinata.cloud/ipfs/QmR3kpCusAKYjSYz3Dzh8bHfAaaCNE7N1sBdbvP8opGXKd/${tokenId}.png`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendClaimRewardsMessage = async (address: string, amount: number, transactionId: string) => {
  const message = [
    `Wallet address ${address} has claimed rewards of ${amount} $SEEDS`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendCreateBuyOrder = async (address: string, quantity: number, price: number, transactionId: string) => {
  const message = [
    `Wallet address ${address} has created a buy offer`,
    `Amount: ${quantity}, Unit Price: ${price}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendCreateSellOrder = async (address: string, quantity: number, price: number, transactionId: string) => {
  const message = [
    `Wallet address ${address} has created a sell offer`,
    `Amount: ${quantity}, Unit Price: ${price}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendBoughtTokenMessage = async (address: string, quantity: number, price: number, orderId: string, transactionId: string) => {
  const message = [
    `Wallet address ${address} has bought ${quantity} $SEEDS`,
    `Amount: ${quantity}, Unit Price: ${price}`,
    `Offer: ${orderId}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendSoldTokenMessage = async (address: string, quantity: number, price: number, orderId: string, transactionId: string) => {
  const message = [
    `Wallet address ${address} has sold ${quantity} $SEEDS`,
    `Amount: ${quantity}, Unit Price: ${price}`,
    `Offer: ${orderId}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendUpdateBuyOrderMessage = async (address: string, quantity: number, price: number, orderId: string, transactionId: string) => {
  const message = [
    `Wallet address ${address} has updated the buy offer`,
    `Amount: ${quantity}, Unit Price: ${price}`,
    `Offer: ${orderId}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendUpdateSellOrderMessage = async (address: string, quantity: number, price: number, orderId: string, transactionId: string) => {
  const message = [
    `Wallet address ${address} has updated the sell offer`,
    `Amount: ${quantity}, Unit Price: ${price}`,
    `Order: ${orderId}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendRemoveSellOrderMessage = async (address: string, orderId: string, transactionId: string) => {
  const message = [
    `Wallet address ${address} has removed a sell offer`,
    `Order: ${orderId}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};

export const sendRemoveBuyOrderMessage = async (address: string, orderId: string, transactionId: string) => {
  const message = [
    `Wallet address ${address} has removed a buy offer`,
    `Order: ${orderId}`,
    `Transaction: https://explorer.exosama.com/tx/${transactionId}`,
  ].join('\n');
  sendDiscordMessage(message);
};
