import { useCallback } from 'react';
import { ethers } from 'ethers';
import { CONTRACT_ERC1155_NFT } from '../../constants';
import abis from './abi_erc1155.json';
import { OwnedToken } from 'components/types';

const abi = abis.abi;

const buildUrl = (uri: string, tokenId: number) => {
  //return uri.replace('{id}', tokenId.toString());
  // return `https://ipfs.io/ipfs/QmTHrtcg5fYTTuSc6XBRDCwpWgVVS9U8Lv2PamWLrwGoKT/${tokenId}.png`;
  return `https://ipfs.io/ipfs/QmejFKgBydfuyKqxzY3Kx8hVktdgQAQ6nT2wdkTg3gup8C/${tokenId}.png`;
};

export const useERC1155 = () => {
  const setApprovalForAll = useCallback(async (spender: string) => {
    if (!window.ethereum) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(CONTRACT_ERC1155_NFT, abi, signer);
    const tx = await contract.setApprovalForAll(spender, true);
    return tx.wait();
  }, []);

  const isApprovedForAll = useCallback(
    async (owner: string, spender: string) => {
      if (!window.ethereum) return;

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const contract = new ethers.Contract(CONTRACT_ERC1155_NFT, abi, signer);
      return await contract.isApprovedForAll(owner, spender);
    },
    []
  );

  const balanceOf = useCallback(async (account: string, tokenId: number) => {
    if (!window.ethereum) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(CONTRACT_ERC1155_NFT, abi, signer);

    const uri = await contract.uri(0);
    console.log('uri', uri);

    const result = await contract.balanceOf(account, tokenId);
    return result ? result.toNumber() : 0;
  }, [])

  const getOwnedTokens = useCallback(async (account: string) => {
    if (!window.ethereum) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(CONTRACT_ERC1155_NFT, abi, signer);

    const uri = await contract.uri(0);
    console.log('uri', uri);

    const result = await contract.ownedTokenBalances(account);
    if (result && result.length) {
      return result
        .map((item: any) => {
          const tokenId = item[0].toNumber();
          const balance = item[1].toNumber();
          return {
            numericId: tokenId,
            balance,
            metadata: {
              image: buildUrl(uri, tokenId),
            },
          } as OwnedToken;
        })
        .filter((i: any) => i.balance > 0);
    }

    return Promise.resolve([]);
  }, []);

  const transfer = useCallback(async (from: string, to: string, tokenId: number, amount: number) => {
    if (!window.ethereum) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(CONTRACT_ERC1155_NFT, abi, signer);
    const result = await contract.safeTransferFrom(from, to, tokenId, amount, '0x00');
    console.log('transfer', result);

    return Promise.resolve([]);
  }, []);

  return {
    setApprovalForAll,
    isApprovedForAll,
    balanceOf,
    getOwnedTokens,
    transfer,
  };
};

export default useERC1155;
