import { useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import styled from '@emotion/styled';

const StyledSelect = styled(Select)`
    height: 44px;
    width: calc(100% - 64px);
    color: white;
    paddingLeft: 20px;
    paddingRight: 20px;
    border: 1px solid rgba(0,0,0,0.4);
    borderRadius: 20px;
    background: transparent;
    
    & > option: {
        color: black;
        height: 76px;
        padding: 20px;
    }
    &:focus-visible: {
        outline: none
    }
}
`;

const AddBuyOffer = ({ tokenId, setTokenId }: any) => {
  return (
    <StyledSelect
      value={tokenId}
      onChange={(e: any) => setTokenId(e.target.value)}
    >
      <MenuItem value={1}>Token 1</MenuItem>
      <MenuItem value={2}>Token 2</MenuItem>
      <MenuItem value={3}>Token 3</MenuItem>
    </StyledSelect>
  );
};

export default AddBuyOffer;
