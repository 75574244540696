import { createContext, useContext } from 'react';
import { OwnedToken } from 'components/types';

export type BudOrder = {
  id: string;
  owner: string;
  tokenId: number;
  price: any;
  quantity: any;
  orderType: number;
  createdAt: number;
  expiration: number;
};

interface BudMarketContext {
  loading: boolean;
  account?: string | null;
  balance: number;
  seedBalance: number;
  tokenBalance:number;
  orders: BudOrder[];
  ownedOrders: BudOrder[];
  refresh: () => Promise<void>;
}

export const marketContext = createContext<BudMarketContext | null>(null);

export const useMarketContext = () => useContext(marketContext)!;

export default marketContext.Provider;
