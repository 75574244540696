import { useEffect, useMemo, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import { toast } from 'react-toastify';

import { toastOptions, shortAddress } from 'utils/utils';

import useERC1155 from 'hooks/erc1155/useERC1155';
import { useMarketContext } from 'context/BudMarketContext';

import ItemRow from 'components/Marketplace/ItemRow';
import InputInteger from 'components/Marketplace/InputInteger';
import ActionButton from 'components/Marketplace/ActionButton';
import MarketDialog from 'components/Marketplace/MarketDialog';
import SelectToken from '../SelectToken';

const Transfer = ({ onClose }: any) => {
  const { account, balance, refresh } = useMarketContext();
  const { balanceOf, transfer } = useERC1155();
  const [recipient, setRecipient] = useState('0x');
  const [quantity, setQuantity] = useState(0);
  const [tokenId, setTokenId] = useState(1);
  const [tokenBalance, setTokenBalance] = useState(0);

  const refreshTokenBalance = async () => {
    if (account) {
      const balance = await balanceOf(account, tokenId);
      setTokenBalance(balance);
    }
  };

  useEffect(() => {
    refreshTokenBalance();
  }, [account, tokenId]);

  const onSubmit = async () => {
    if (!account) {
      toast.warn('Please connect your wallet!');
      return;
    }
    if (!recipient || !recipient.startsWith('0x')) {
      toast.warn('Please input recipient address!');
      return;
    }
    if (recipient === account) {
      toast.warn('You are going to send your own wallet!');
      return;
    }
    if (quantity <= 0) {
      toast.warn('Please input quantity!');
      return;
    }

    const toastId = toast.loading('Transfering NFT token...');

    try {
      const result = await transfer(account, recipient, tokenId, quantity);
      if (!result) {
        toast.update(toastId, toastOptions('Failed to create send token!'));
      } else {
        refreshTokenBalance();
        refresh();
        toast.update(
          toastId,
          toastOptions('You have been sent token successfully!', 'success')
        );
      }
    } catch (err: any) {
      console.error(err);
      toast.update(
        toastId,
        toastOptions(err?.data?.message || 'Something went wrong!', 'error')
      );
    }
  };

  const disabled = useMemo(() => {
    if (balance <= 0 || quantity <= 0) {
      return true;
    }
    if (quantity > tokenBalance) {
      return true;
    }
    if (!recipient || !recipient.startsWith('0x')) {
      return true;
    }
    return false;
  }, [balance, recipient, quantity, tokenBalance]);

  return (
    <MarketDialog
      title="TRANSFER NFT"
      actions={
        <>
          <ActionButton onClick={onSubmit} disabled={disabled}>
            Transfer
          </ActionButton>
          <ActionButton onClick={onClose}>Close</ActionButton>
        </>
      }
      onClose={onClose}
    >
      <>
        <Grid container spacing={4} alignItems="center">
          <ItemRow heading="Recipient">
            <TextField
              variant="outlined"
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
              sx={{ fontSize: 20 }}
            />
          </ItemRow>

          <ItemRow heading="Token">
            <SelectToken
              tokenId={tokenId}
              setTokenId={setTokenId}
            ></SelectToken>
          </ItemRow>

          <ItemRow heading="Quantity to send">
            <InputInteger
              value={quantity}
              onChange={setQuantity}
              onButtonClick={() => setQuantity(tokenBalance)}
            />
          </ItemRow>
        </Grid>

        <Divider light />

        <Grid container spacing={4} alignItems="center">
          <ItemRow heading="You have">{tokenBalance} NFT</ItemRow>
          <ItemRow heading="You give">{quantity} NFT</ItemRow>
          <ItemRow heading="Recipient">{shortAddress(recipient)}</ItemRow>
        </Grid>
      </>
    </MarketDialog>
  );
};

export default Transfer;
