import { useEffect, useState } from 'react';
import { Button, Input } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  color: white;
  background: #00ce4c;
`;

interface Props extends InputBaseProps {
  value: number;
  onChange: (e: any) => void;
  onButtonClick?: () => void;
}

const InputNumber = ({
  value: _value,
  onChange,
  onButtonClick,
  ...props
}: Props) => {
  const [value, setValue] = useState<string>(_value ? _value.toString() : '0');

  useEffect(() => setValue(_value.toString()), [_value]);

  const handleChange = (e: any) => {
    const regex = /^\d*\.?\d*?$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValue(e.target.value);
      onChange(Number(e.target.value));
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        p: '0px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
      }}
    >
      <InputBase
        type="text"
        sx={{ color: 'white', fontSize: 20 }}
        value={value}
        onChange={handleChange}
        {...props}
      />
      {!!onButtonClick && (
        <StyledButton aria-label="MAX" onClick={onButtonClick}>
          MAX
        </StyledButton>
      )}
    </Paper>
  );
};

export default InputNumber;
