import { useCallback } from 'react';
import { ethers } from 'ethers';
import { CONTRACT_BUDDIES_MARKET } from '../../constants';
import { useERC1155 } from './useERC1155';
import abis from './abi_bud_market.json';

const contractAddress = CONTRACT_BUDDIES_MARKET;
const abi = abis.abi;

export const useBudMarket = () => {
  const { isApprovedForAll, setApprovalForAll } = useERC1155();

  const getOrders = useCallback(async () => {
    if (!window.ethereum) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);

    return await contract.getOrderArray();
  }, []);

  const addSellOrder = useCallback(
    async (
      address: string,
      tokenId: number,
      quantity: number,
      price: number,
      expiration: number
    ) => {
      if (!window.ethereum) return;

      const approved = await isApprovedForAll(address, contractAddress);
      if (!approved) {
        const ar = await setApprovalForAll(contractAddress);
        if (!ar) {
          console.error('approve error');
          return null;
        }
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const priceToWei = ethers.utils.parseUnits(price.toString(), 'ether');
      const tx = await contract.addSellOrder(tokenId, quantity, priceToWei, expiration);
      return tx.wait();
    },
    [isApprovedForAll, setApprovalForAll]
  );

  const addBuyOrder = useCallback(
    async (
      tokenId: number,
      quantity: number,
      price: number,
      expiration: number
    ) => {
      if (!window.ethereum) return;

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const priceToWei = ethers.utils.parseUnits(price.toString(), 'ether');
      const totalPrice = ethers.utils.parseUnits((quantity * price).toString(), 'ether');
      const tx = await contract.addBuyOrder(tokenId, quantity, priceToWei, expiration, { value: totalPrice });
      return tx.wait();
    },
    []
  );

  const updateSellOrder = useCallback(
    async (
      address: string,
      orderId: string,
      quantity: number,
      price: number,
      expiration: number
    ) => {
      if (!window.ethereum) return;

      const allownced = await isApprovedForAll(address, contractAddress);
      if (!allownced) {
        const ar = await setApprovalForAll(contractAddress);
        if (!ar) {
          console.error('approve error');
          return null;
        }
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const priceToWei = ethers.utils.parseUnits(price.toString(), 'ether');
      const tx = await contract.updateSellOrder(orderId, quantity, priceToWei, expiration);
      return tx.wait();
    },
    [isApprovedForAll, setApprovalForAll]
  );

  const updateBuyOrder = useCallback(
    async (
      order: any,
      quantity: number,
      price: number,
      expiration: number
    ) => {
      if (!window.ethereum) return;

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, abi, signer);

      const priceToWei = ethers.utils.parseUnits(price.toString(), 'ether');
      
      const newPrice = quantity * price;
      const oldPrice = order.quantity * order.price;
      const extraPrice = newPrice - oldPrice;

      let params = undefined;
      if (extraPrice > 0) {
        const totalPrice = ethers.utils.parseUnits(extraPrice.toString(), 'ether');
        params = { value: totalPrice };
      }

      if (params) {
        const tx = await contract.updateBuyOrder(order.id, quantity, priceToWei, expiration, params);
        return tx.wait();
      } else {
        const tx = await contract.updateBuyOrder(order.id, quantity, priceToWei, expiration);
        return tx.wait();
      }
    },
    []
  );

  const removeOrder = useCallback(async (orderId: string) => {
    if (!window.ethereum) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);

    const tx = await contract.removeOrder(orderId);
    return tx.wait();
  }, []);

  const buyTokenByOrderId = useCallback(async (orderId: string, quantity: number, unitPrice: number) => {
    if (!window.ethereum) return;
    console.log('buyTokenByOrderId', orderId, quantity, unitPrice);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);

    const totalPrice = ethers.utils.parseUnits((unitPrice * quantity).toString(), 'ether');
    const tx = await contract.buyTokenByOrderId(orderId, quantity, { value: totalPrice });
    console.log('buyTokenByOrderId', tx);
    return tx.wait();
  }, []);

  const sellTokenByOrderId = useCallback(async (address: string, orderId: string, quantity: number) => {
    if (!window.ethereum) return;
    console.log('sellTokenByOrderId', orderId, quantity);

    const allownced = await isApprovedForAll(address, contractAddress);
    if (!allownced) {
      const ar = await setApprovalForAll(contractAddress);
      if (!ar) {
        console.error('approve error');
        return null;
      }
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, signer);

    const tx = await contract.sellTokenByOrderId(orderId, quantity);
    console.log('sellTokenByOrderId', tx);
    return tx.wait();
  }, [isApprovedForAll, setApprovalForAll]);

  const userStakeInfo = useCallback(async (address: string) => {
    if (!window.ethereum) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(contractAddress, abi, signer);
    return await contract.userStakeInfo(address);
  }, []);

  return {
    getOrders,
    addSellOrder,
    addBuyOrder,
    updateSellOrder,
    updateBuyOrder,
    removeOrder,
    buyTokenByOrderId,
    sellTokenByOrderId,
    userStakeInfo,
  };
};

export default useBudMarket;
