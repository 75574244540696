import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { toast } from 'react-toastify';

import { CONTRACT_MARKETPLACE } from '../../../constants';
import { toastOptions, shortAddress, formatNumber } from 'utils/utils';

import { useBudMarket } from 'hooks/erc1155/useBudMarket';
import useERC1155 from 'hooks/erc1155/useERC1155';
import { useMarketContext } from 'context/BudMarketContext';

import ItemRow from 'components/Marketplace/ItemRow';
import InputNumber from 'components/Marketplace/InputNumber';
import InputInteger from 'components/Marketplace/InputInteger';
import ActionButton from 'components/Marketplace/ActionButton';
import MarketDialog from 'components/Marketplace/MarketDialog';
import SelectToken from '../SelectToken';

import * as discord from 'utils/discord';

const AddSellOffer = ({ onClose }: any) => {
  const { account, refresh } = useMarketContext();
  const { balanceOf } = useERC1155();
  const { addSellOrder } = useBudMarket();
  const [quantity, setQuantity] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tokenId, setTokenId] = useState(1);
  const [tokenBalance, setTokenBalance] = useState(0);

  useEffect(() => {
    const get = async () => {
      if (account) {
        const balance = await balanceOf(account, tokenId);
        setTokenBalance(balance);
      }
    };
    get();
  }, [account, tokenId]);

  const onChangeTokenId = async (tokenId: number) => {
    setTokenId(tokenId);
    setTokenBalance(0);
  };

  const onSubmit = async () => {
    if (!account) {
      toast.warn('Please connect your wallet!');
      return;
    }
    if (quantity <= 0) {
      toast.warn('Please input quantity!');
      return;
    }
    if (unitPrice <= 0) {
      toast.warn('Please input price!');
      return;
    }

    setLoading(true);
    const toastId = toast.loading('Creating your sell offer...');

    try {
      const result = await addSellOrder(
        account,
        tokenId,
        quantity,
        unitPrice,
        0
      );
      if (!result) {
        toast.update(toastId, toastOptions('Failed to create sell offer!'));
      } else {
        // discord.sendCreateSellOrder(
        //   account,
        //   quantity,
        //   unitPrice,
        //   result.transactionHash
        // );

        refresh();
        toast.update(
          toastId,
          toastOptions(
            'You have been created sell offer successfully!',
            'success'
          )
        );
      }
    } catch (err: any) {
      console.error(err);
      toast.update(
        toastId,
        toastOptions(err?.data?.message || 'Something went wrong!', 'error')
      );
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const gainAmount = formatNumber(quantity * unitPrice);

  const disabled = useMemo(() => {
    if (quantity <= 0 || quantity > tokenBalance) {
      return true;
    }
    if (unitPrice <= 0) {
      return true;
    }
    return false;
  }, [quantity, unitPrice, tokenBalance]);

  return (
    <MarketDialog
      title="Create Sell Offer"
      actions={
        <>
          <ActionButton onClick={onSubmit} disabled={disabled || loading}>
            Place offer
          </ActionButton>
          <ActionButton onClick={onClose} disabled={loading}>
            Close
          </ActionButton>
        </>
      }
      onClose={onClose}
    >
      <>
        <Grid container spacing={4} alignItems="center">
          <ItemRow heading="Address">
            {shortAddress(CONTRACT_MARKETPLACE)}
          </ItemRow>
        </Grid>

        <Divider light />

        <Grid container spacing={4} alignItems="center">
          <ItemRow heading="Token">
            <SelectToken
              tokenId={tokenId}
              setTokenId={setTokenId}
            ></SelectToken>
          </ItemRow>
          <ItemRow heading="Quantity to sell">
            <InputInteger
              value={quantity}
              onChange={setQuantity}
              onButtonClick={() => setQuantity(tokenBalance)}
            />
          </ItemRow>
          <ItemRow heading="Price per unit">
            <InputNumber value={unitPrice} onChange={setUnitPrice} />
          </ItemRow>
        </Grid>

        <Divider light />

        <Grid container spacing={4} alignItems="center">
          <ItemRow heading="You have">{tokenBalance} NFT</ItemRow>
          <ItemRow heading="You give">{quantity} NFT</ItemRow>
          <ItemRow heading="Royalty fee">{'0'}</ItemRow>
          <ItemRow heading="You get">{gainAmount} SAMA</ItemRow>
        </Grid>
      </>
    </MarketDialog>
  );
};

export default AddSellOffer;
