import { useEffect } from 'react';

import { MarketProvider } from 'context/BudMarketContext/Provider';
import { useMarketContext } from 'context/BudMarketContext';
import TokenProfile from './TokenProfile';
import ItemTable from './ItemTable';

const BudItems = () => {
  const { refresh } = useMarketContext();
  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      <TokenProfile />
      <ItemTable />
    </>
  );
};

const BudItemsWrapper = () => (
  <MarketProvider>
    <BudItems />
  </MarketProvider>
);
export default BudItemsWrapper;
